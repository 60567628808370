import { privateClient } from "./client";
import i18n from "i18next";

export async function getAllUserAdventures() {
  return await privateClient.get("user_adventures").json();
}

export async function getAdventure({ queryKey }) {
  const [_key, { id }] = queryKey;
  return await privateClient.get(`user_adventures/${id}`).json();
}

export async function getSpot({ queryKey }) {
  const [_key, { adventureId, spotId }] = queryKey;
  return await privateClient
    .get(`user_adventures/${adventureId}/spots/${spotId}`)
    .json();
}

export async function completeSpot({ adventureId, spotId, taskType, answer }) {
  return await privateClient
    .put(`user_adventures/${adventureId}/spots/${spotId}/complete`, {
      json: { task_type: taskType, answer }
    })
    .json();
}

export async function approveExtensionPurchase({ adventureId }) {
  return await privateClient
    .put(`user_adventures/${adventureId}/extend_adventure`)
    .json();
}
export async function disableExtensionModal({ adventureId }) {
  return await privateClient
    .put(`user_adventures/${adventureId}/hide_extension_modal`)
    .json();
}

export async function activateAdventure({ code }) {
  const codeUppercase = code.toUpperCase();
  return await privateClient
    .post(`user_adventures`, { json: { code: codeUppercase } })
    .json();
}

export async function changePassword({ password, passwordConfirmation }) {
  return await privateClient
    .put("auth/password", {
      json: {
        password,
        password_confirmation: passwordConfirmation,
        locale: i18n.language
      }
    })
    .json();
}
