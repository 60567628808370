import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translations
import cityspottingFi from "./cityspotting-fi.json";
import cityspottingEn from "./cityspotting-en.json";
import cityspottingSv from "./cityspotting-sv.json";
import mapiosaFi from "./mapiosa-fi.json";
import mapiosaEn from "./mapiosa-en.json";
import mapiosaSv from "./mapiosa-sv.json";

// Define translations
const translations = {
  cityspotting: {
    en: cityspottingEn,
    fi: cityspottingFi,
    sv: cityspottingSv
  },
  mapiosa: {
    en: mapiosaEn,
    fi: mapiosaFi,
    sv: mapiosaSv
  }
};

// Select the correct translation
const site = process.env.SITE || "cityspotting";
const selectedTranslation = translations[site];

// Create the resources object
const resources = {
  en: {
    translation: selectedTranslation.en
  },
  fi: {
    translation: selectedTranslation.fi
  },
  sv: {
    translation: selectedTranslation.sv
  }
};

const domain = window.location.hostname;

let defaultLanguage;
if (domain.endsWith(".fi")) {
  defaultLanguage = "fi";
} else if (domain.endsWith(".se")) {
  defaultLanguage = "sv";
} else {
  defaultLanguage = "fi";
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language") || defaultLanguage,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
