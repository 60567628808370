// Assets
// Locked pins
import pinLockedArchitecture from "@/assets/pins/pin-locked-architecture.png";
import pinLockedNature from "@/assets/pins/pin-locked-nature.png";
import pinLockedSport from "@/assets/pins/pin-locked-sport.png";
import pinLockedAddToCart from "@/assets/pins/pin-locked-add-to-cart.png";
import pinLockedAward from "@/assets/pins/pin-locked-award.png";
import pinLockedCoffee from "@/assets/pins/pin-locked-coffee.png";
import pinLockedDiscussion from "@/assets/pins/pin-locked-discussion.png";
import pinLockedFavourite from "@/assets/pins/pin-locked-favourite.png";
import pinLockedGift from "@/assets/pins/pin-locked-gift.png";
import pinLockedPeople from "@/assets/pins/pin-locked-people.png";
import pinLockedLocked from "@/assets/pins/pin-locked-locked.png";
import pinLockedMystery from "@/assets/pins/pin-locked-mystery.png";
import pinLockedPhotography from "@/assets/pins/pin-locked-photography.png";
import pinLockedRecommendation from "@/assets/pins/pin-locked-recommendation.png";
import pinLockedSilent from "@/assets/pins/pin-locked-silent.png";
import pinLockedSiteSpecific from "@/assets/pins/pin-locked-site-specific.png";
import pinLockedSoundSpecific from "@/assets/pins/pin-locked-sound-specific.png";
import pinLockedSpecialSpot from "@/assets/pins/pin-locked-special-spot.png";
import pinLockedSponsored from "@/assets/pins/pin-locked-sponsored.png";
import pinLockedTickets from "@/assets/pins/pin-locked-tickets.png";
import pinLockedTimeSpecific from "@/assets/pins/pin-locked-time-specific.png";
import pinLockedWeatherSpecific from "@/assets/pins/pin-locked-weather-specific.png";

// Visited pins
import pinVisitedArchitecture from "@/assets/pins/pin-visited-architecture.png";
import pinVisitedNature from "@/assets/pins/pin-visited-nature.png";
import pinVisitedSport from "@/assets/pins/pin-visited-sport.png";
import pinVisitedAddToCart from "@/assets/pins/pin-visited-add-to-cart.png";
import pinVisitedAward from "@/assets/pins/pin-visited-award.png";
import pinVisitedCoffee from "@/assets/pins/pin-visited-coffee.png";
import pinVisitedDiscussion from "@/assets/pins/pin-visited-discussion.png";
import pinVisitedFavourite from "@/assets/pins/pin-visited-favourite.png";
import pinVisitedGift from "@/assets/pins/pin-visited-gift.png";
import pinVisitedPeople from "@/assets/pins/pin-visited-people.png";
import pinVisitedLocked from "@/assets/pins/pin-visited-locked.png";
import pinVisitedMystery from "@/assets/pins/pin-visited-mystery.png";
import pinVisitedPhotography from "@/assets/pins/pin-visited-photography.png";
import pinVisitedRecommendation from "@/assets/pins/pin-visited-recommendation.png";
import pinVisitedSilent from "@/assets/pins/pin-visited-silent.png";
import pinVisitedSiteSpecific from "@/assets/pins/pin-visited-site-specific.png";
import pinVisitedSoundSpecific from "@/assets/pins/pin-visited-sound-specific.png";
import pinVisitedSpecialSpot from "@/assets/pins/pin-visited-special-spot.png";
import pinVisitedSponsored from "@/assets/pins/pin-visited-sponsored.png";
import pinVisitedTickets from "@/assets/pins/pin-visited-tickets.png";
import pinVisitedTimeSpecific from "@/assets/pins/pin-visited-time-specific.png";
import pinVisitedWeatherSpecific from "@/assets/pins/pin-visited-weather-specific.png";

// Completed pins
import pinCompletedArchitecture from "@/assets/pins/pin-completed-architecture.png";
import pinCompletedNature from "@/assets/pins/pin-completed-nature.png";
import pinCompletedSport from "@/assets/pins/pin-completed-sport.png";
import pinCompletedAddToCart from "@/assets/pins/pin-completed-add-to-cart.png";
import pinCompletedAward from "@/assets/pins/pin-completed-award.png";
import pinCompletedCoffee from "@/assets/pins/pin-completed-coffee.png";
import pinCompletedDiscussion from "@/assets/pins/pin-completed-discussion.png";
import pinCompletedFavourite from "@/assets/pins/pin-completed-favourite.png";
import pinCompletedGift from "@/assets/pins/pin-completed-gift.png";
import pinCompletedPeople from "@/assets/pins/pin-completed-people.png";
import pinCompletedLocked from "@/assets/pins/pin-completed-locked.png";
import pinCompletedMystery from "@/assets/pins/pin-completed-mystery.png";
import pinCompletedPhotography from "@/assets/pins/pin-completed-photography.png";
import pinCompletedRecommendation from "@/assets/pins/pin-completed-recommendation.png";
import pinCompletedSilent from "@/assets/pins/pin-completed-silent.png";
import pinCompletedSiteSpecific from "@/assets/pins/pin-completed-site-specific.png";
import pinCompletedSoundSpecific from "@/assets/pins/pin-completed-sound-specific.png";
import pinCompletedSpecialSpot from "@/assets/pins/pin-completed-special-spot.png";
import pinCompletedSponsored from "@/assets/pins/pin-completed-sponsored.png";
import pinCompletedTickets from "@/assets/pins/pin-completed-tickets.png";
import pinCompletedTimeSpecific from "@/assets/pins/pin-completed-time-specific.png";
import pinCompletedWeatherSpecific from "@/assets/pins/pin-completed-weather-specific.png";

export function useMarkerImages() {
  const markerImages = [
    {
      url: pinLockedArchitecture,
      id: "pin-locked-architecture",
      status: "locked",
      markerIcon: "architecture"
    },
    {
      url: pinLockedNature,
      id: "pin-locked-nature",
      status: "locked",
      markerIcon: "nature"
    },
    {
      url: pinLockedSport,
      id: "pin-locked-sport",
      status: "locked",
      markerIcon: "sport"
    },
    {
      url: pinLockedAddToCart,
      id: "pin-locked-add-to-cart",
      status: "locked",
      markerIcon: "add_to_cart"
    },
    {
      url: pinLockedAward,
      id: "pin-locked-award",
      status: "locked",
      markerIcon: "award"
    },
    {
      url: pinLockedCoffee,
      id: "pin-locked-coffee",
      status: "locked",
      markerIcon: "coffee"
    },
    {
      url: pinLockedDiscussion,
      id: "pin-locked-discussion",
      status: "locked",
      markerIcon: "discussion"
    },
    {
      url: pinLockedFavourite,
      id: "pin-locked-favourite",
      status: "locked",
      markerIcon: "favourite"
    },
    {
      url: pinLockedGift,
      id: "pin-locked-gift",
      status: "locked",
      markerIcon: "gift"
    },
    {
      url: pinLockedPeople,
      id: "pin-locked-people",
      status: "locked",
      markerIcon: "people"
    },
    {
      url: pinLockedLocked,
      id: "pin-locked-locked",
      status: "locked",
      markerIcon: "locked"
    },
    {
      url: pinLockedMystery,
      id: "pin-locked-mystery",
      status: "locked",
      markerIcon: "mystery"
    },
    {
      url: pinLockedPhotography,
      id: "pin-locked-photography",
      status: "locked",
      markerIcon: "photography"
    },
    {
      url: pinLockedRecommendation,
      id: "pin-locked-recommendation",
      status: "locked",
      markerIcon: "recommendation"
    },
    {
      url: pinLockedSilent,
      id: "pin-locked-silent",
      status: "locked",
      markerIcon: "silent"
    },
    {
      url: pinLockedSiteSpecific,
      id: "pin-locked-site-specific",
      status: "locked",
      markerIcon: "site_specific"
    },
    {
      url: pinLockedSoundSpecific,
      id: "pin-locked-sound-specific",
      status: "locked",
      markerIcon: "sound_specific"
    },
    {
      url: pinLockedSpecialSpot,
      id: "pin-locked-special-spot",
      status: "locked",
      markerIcon: "special_spot"
    },
    {
      url: pinLockedSponsored,
      id: "pin-locked-sponsored",
      status: "locked",
      markerIcon: "sponsored"
    },
    {
      url: pinLockedTickets,
      id: "pin-locked-tickets",
      status: "locked",
      markerIcon: "tickets"
    },
    {
      url: pinLockedTimeSpecific,
      id: "pin-locked-time-specific",
      status: "locked",
      markerIcon: "time_specific"
    },
    {
      url: pinLockedWeatherSpecific,
      id: "pin-locked-weather-specific.png",
      status: "locked",
      markerIcon: "weather_specific"
    },
    {
      url: pinVisitedArchitecture,
      id: "pin-visited-architecture",
      status: "visited",
      markerIcon: "architecture"
    },
    {
      url: pinVisitedNature,
      id: "pin-visited-nature",
      status: "visited",
      markerIcon: "nature"
    },
    {
      url: pinVisitedSport,
      id: "pin-visited-sport",
      status: "visited",
      markerIcon: "sport"
    },
    {
      url: pinVisitedAddToCart,
      id: "pin-visited-add-to-cart",
      status: "visited",
      markerIcon: "add_to_cart"
    },
    {
      url: pinVisitedAward,
      id: "pin-visited-award",
      status: "visited",
      markerIcon: "award"
    },
    {
      url: pinVisitedCoffee,
      id: "pin-visited-coffee",
      status: "visited",
      markerIcon: "coffee"
    },
    {
      url: pinVisitedDiscussion,
      id: "pin-visited-discussion",
      status: "visited",
      markerIcon: "discussion"
    },
    {
      url: pinVisitedFavourite,
      id: "pin-visited-favourite",
      status: "visited",
      markerIcon: "favourite"
    },
    {
      url: pinVisitedGift,
      id: "pin-visited-gift",
      status: "visited",
      markerIcon: "gift"
    },
    {
      url: pinVisitedPeople,
      id: "pin-visited-people",
      status: "visited",
      markerIcon: "people"
    },
    {
      url: pinVisitedLocked,
      id: "pin-visited-locked",
      status: "visited",
      markerIcon: "locked"
    },
    {
      url: pinVisitedMystery,
      id: "pin-visited-mystery",
      status: "visited",
      markerIcon: "mystery"
    },
    {
      url: pinVisitedPhotography,
      id: "pin-visited-photography",
      status: "visited",
      markerIcon: "photography"
    },
    {
      url: pinVisitedRecommendation,
      id: "pin-visited-recommendation",
      status: "visited",
      markerIcon: "recommendation"
    },
    {
      url: pinVisitedSilent,
      id: "pin-visited-silent",
      status: "visited",
      markerIcon: "silent"
    },
    {
      url: pinVisitedSiteSpecific,
      id: "pin-visited-site-specific",
      status: "visited",
      markerIcon: "site_specific"
    },
    {
      url: pinVisitedSoundSpecific,
      id: "pin-visited-sound-specific",
      status: "visited",
      markerIcon: "sound_specific"
    },
    {
      url: pinVisitedSpecialSpot,
      id: "pin-visited-special-spot",
      status: "visited",
      markerIcon: "special_spot"
    },
    {
      url: pinVisitedSponsored,
      id: "pin-visited-sponsored",
      status: "visited",
      markerIcon: "sponsored"
    },
    {
      url: pinVisitedTickets,
      id: "pin-visited-tickets",
      status: "visited",
      markerIcon: "tickets"
    },
    {
      url: pinVisitedTimeSpecific,
      id: "pin-visited-time-specific",
      status: "visited",
      markerIcon: "time_specific"
    },
    {
      url: pinVisitedWeatherSpecific,
      id: "pin-visited-weather-specific.png",
      status: "visited",
      markerIcon: "weather_specific"
    },
    {
      url: pinCompletedArchitecture,
      id: "pin-completed-architecture",
      status: "completed",
      markerIcon: "architecture"
    },
    {
      url: pinCompletedNature,
      id: "pin-completed-nature",
      status: "completed",
      markerIcon: "nature"
    },
    {
      url: pinCompletedSport,
      id: "pin-completed-sport",
      status: "completed",
      markerIcon: "sport"
    },
    {
      url: pinCompletedAddToCart,
      id: "pin-completed-add-to-cart",
      status: "completed",
      markerIcon: "add_to_cart"
    },
    {
      url: pinCompletedAward,
      id: "pin-completed-award",
      status: "completed",
      markerIcon: "award"
    },
    {
      url: pinCompletedCoffee,
      id: "pin-completed-coffee",
      status: "completed",
      markerIcon: "coffee"
    },
    {
      url: pinCompletedDiscussion,
      id: "pin-completed-discussion",
      status: "completed",
      markerIcon: "discussion"
    },
    {
      url: pinCompletedFavourite,
      id: "pin-completed-favourite",
      status: "completed",
      markerIcon: "favourite"
    },
    {
      url: pinCompletedGift,
      id: "pin-completed-gift",
      status: "completed",
      markerIcon: "gift"
    },
    {
      url: pinCompletedPeople,
      id: "pin-completed-people",
      status: "completed",
      markerIcon: "people"
    },
    {
      url: pinCompletedLocked,
      id: "pin-completed-locked",
      status: "completed",
      markerIcon: "locked"
    },
    {
      url: pinCompletedMystery,
      id: "pin-completed-mystery",
      status: "completed",
      markerIcon: "mystery"
    },
    {
      url: pinCompletedPhotography,
      id: "pin-completed-photography",
      status: "completed",
      markerIcon: "photography"
    },
    {
      url: pinCompletedRecommendation,
      id: "pin-completed-recommendation",
      status: "completed",
      markerIcon: "recommendation"
    },
    {
      url: pinCompletedSilent,
      id: "pin-completed-silent",
      status: "completed",
      markerIcon: "silent"
    },
    {
      url: pinCompletedSiteSpecific,
      id: "pin-completed-site-specific",
      status: "completed",
      markerIcon: "site_specific"
    },
    {
      url: pinCompletedSoundSpecific,
      id: "pin-completed-sound-specific",
      status: "completed",
      markerIcon: "sound_specific"
    },
    {
      url: pinCompletedSpecialSpot,
      id: "pin-completed-special-spot",
      status: "completed",
      markerIcon: "special_spot"
    },
    {
      url: pinCompletedSponsored,
      id: "pin-completed-sponsored",
      status: "completed",
      markerIcon: "sponsored"
    },
    {
      url: pinCompletedTickets,
      id: "pin-completed-tickets",
      status: "completed",
      markerIcon: "tickets"
    },
    {
      url: pinCompletedTimeSpecific,
      id: "pin-completed-time-specific",
      status: "completed",
      markerIcon: "time_specific"
    },
    {
      url: pinCompletedWeatherSpecific,
      id: "pin-completed-weather-specific.png",
      status: "completed",
      markerIcon: "weather_specific"
    }
  ];
  return markerImages;
}
