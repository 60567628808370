import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  useMemo
} from "react";

import { validateToken } from "@api";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    const initialSession = getSessionStorage();

    if (initialSession) {
      validateToken(initialSession)
        .then(({ success }) => {
          if (success) {
            setIsLoggedIn(true);
          }
        })
        .catch(() => {
          // TODO: Log error
        })
        .finally(() => {
          setInitialLoadDone(true);
        });
    } else {
      setInitialLoadDone(true);
    }
  }, []);

  const value = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      isLoggingIn,
      setIsLoggingIn,
      initialLoadDone
    }),
    [isLoggedIn, setIsLoggedIn, isLoggingIn, setIsLoggingIn, initialLoadDone]
  );

  return (
    <AuthContext.Provider value={value}>
      {initialLoadDone && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function getSessionStorage() {
  const raw = window.localStorage.getItem(`${process.env.SITE}-session`);
  if (raw) {
    return JSON.parse(raw);
  }
}

export function setSessionStorage(newSession) {
  window.localStorage.setItem(
    `${process.env.SITE}-session`,
    JSON.stringify(newSession)
  );
  window.localStorage.setItem(`${process.env.SITE}-user`, newSession.uid);
}

export function removeSessionStorage() {
  window.localStorage.removeItem(`${process.env.SITE}-session`);
  window.localStorage.removeItem(`${process.env.SITE}-user`);
}
