import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth.js";

export default function Unauthenticated({ children }) {
  const { isLoggingIn, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggingIn && isLoggedIn) {
      navigate("/adventures");
    }
  }, [isLoggingIn, isLoggedIn, navigate]);

  return children;
}
