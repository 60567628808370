import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Localization i18n
import "./localization/i18n";

// Auth
import { AuthProvider, useAuth } from "@/auth.js";

// Components
import Authenticated from "@components/Authenticated";
import Unauthenticated from "@components/Unauthenticated";
import AdventureList from "@features/AdventureList/AdventureList";
import RegisterWelcome from "@features/RegisterWelcome/RegisterWelcome";
import Adventure from "@features/Adventure/Adventure";
import Settings from "@features/Settings/Settings";
import Activate from "@features/Activate/Activate";
import ChangePassword from "@features/ChangePassword/ChangePassword";
import Start from "@features/Start/Start";
import { ErrorBoundary } from "@components/common";

// Global styles
import "@/styles/reset.css";
import "@/styles/variables.css";
import "@/styles/font.css";
import "@/styles/global.css";
import "@/styles/spacing.css";

const queryClient = new QueryClient();

function MainRoutes() {
  const { initialLoadDone } = useAuth();

  if (!initialLoadDone) {
    return <div>Loading…</div>;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Unauthenticated>
            <Start />
          </Unauthenticated>
        }
      />

      <Route
        path="/welcome"
        element={
          <Authenticated>
            <RegisterWelcome />
          </Authenticated>
        }
      />
      <Route
        path="/adventures"
        element={
          <Authenticated>
            <AdventureList />
          </Authenticated>
        }
      />
      <Route
        path="/settings"
        element={
          <Authenticated>
            <Settings />
          </Authenticated>
        }
      />
      <Route
        path="/change-password"
        element={
          <Authenticated>
            <ChangePassword />
          </Authenticated>
        }
      />
      <Route
        path="/activate"
        element={
          <Authenticated>
            <Activate />
          </Authenticated>
        }
      />
      <Route
        path="/adventure/:adventureId/*"
        element={
          <Authenticated>
            <Adventure />
          </Authenticated>
        }
      />
    </Routes>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <MainRoutes />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
