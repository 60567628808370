import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Localization
import { useTranslation, Trans } from "react-i18next";

// API
import { getAllUserAdventures } from "@api";
import { useAuthQuery } from "@hooks";

// Site config
import { siteTitleTM } from "@/siteConfig";

// UI
import {
  Button,
  H3,
  Loader,
  TopBar,
  NotificationInline,
  CenteredContent,
  TextLink,
  Text
} from "@components/common";

// Assets
import { Settings } from "react-feather";
import IconMap from "@/assets/icons/icon-map.svg";

// Styles
import styles from "./AdventureList.module.css";
import { AdventureCard } from "./AdventureCard/AdventureCard";
import ExpiredModal from "../../components/common/Modal/ExpiredModal";

export default function AdventureList() {
  const { state: locationState } = useLocation();
  const { successMessage } = locationState || {};
  const { t } = useTranslation();
  const [openExtensionModal, setOpenExtensionModal] = useState(false);
  const [expiredExtensionData, setExpiredExtensionData] = useState({});

  const { data: adventures, isLoading } = useAuthQuery(
    "adventures",
    getAllUserAdventures
  );

  return (
    <div className={styles.wrapper}>
      <TopBar
        title={siteTitleTM}
        right={<Button to={"/settings"} icon={Settings} white round />}
      />
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.empty}>
            <div>
              <Loader text={t("common.loading")} />
            </div>
          </div>
        ) : adventures && adventures.length > 0 ? (
          <List
            adventures={adventures}
            successMessage={successMessage}
            openExtensionModal={openExtensionModal}
            setOpenExtensionModal={setOpenExtensionModal}
            setExpiredExtensionData={setExpiredExtensionData}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      <ExpiredModal
        openExtensionModal={openExtensionModal}
        setOpenExtensionModal={setOpenExtensionModal}
        expiredExtensionData={expiredExtensionData}
      />
    </div>
  );
}

function List({
  adventures,
  successMessage,
  openExtensionModal,
  setOpenExtensionModal,
  setExpiredExtensionData
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.inner}>
        {successMessage && (
          <NotificationInline
            success
            message={successMessage}
            className="margin-16 margin-bottom"
          />
        )}
        <ul className={styles.list}>
          {adventures.map((userAdventure) => {
            const {
              id,
              points,
              adventure,
              currentLevel,
              accessEndDate,
              expired,
              expiredSoon,
              showExtensionModal
            } = userAdventure;

            const {
              coverImage,
              title,
              description,
              numberOfSpots,
              locked,
              extendDuration,
              extensionProductSite,
              extensionTextFi,
              extensionTextEn,
              extensionTextSv,
              notExtensible
            } = adventure;

            const handleExpiredModal = ({
              id,
              accessEndDate,
              extendDuration,
              extensionProductSite,
              extensionTextFi,
              extensionTextEn,
              extensionTextSv,
              showExtensionModal
            }) => {
              if (expired === true && notExtensible === true) {
                setOpenExtensionModal(false);
              } else {
                setOpenExtensionModal(true);
              }
              setExpiredExtensionData({
                id,
                accessEndDate,
                extendDuration,
                extensionProductSite,
                extensionTextFi,
                extensionTextEn,
                extensionTextSv,
                showExtensionModal
              });
            };

            return (
              <li key={id} className={styles.adventureItem}>
                <AdventureCard
                  element={Link}
                  to={`/adventure/${id}`}
                  className={styles.adventureLink}
                  currentLevel={currentLevel}
                  points={points}
                  numberOfSpots={numberOfSpots}
                  coverImage={coverImage}
                  title={title}
                  description={description}
                  accessEndDate={accessEndDate}
                  locked={locked}
                  expired={expired}
                  expiredSoon={expiredSoon}
                  handleExpiredModal={handleExpiredModal}
                  openExtensionModal={openExtensionModal}
                  setOpenExtensionModal={setOpenExtensionModal}
                  extendDuration={extendDuration}
                  extensionProductSite={extensionProductSite}
                  extensionTextFi={extensionTextFi}
                  extensionTextEn={extensionTextEn}
                  extensionTextSv={extensionTextSv}
                  showExtensionModal={showExtensionModal}
                  notExtensible={notExtensible}
                  id={id}
                />
              </li>
            );
          })}
          <li className={styles.activateCard}>
            <div className={styles.activateCardInner}>
              <H3
                size="base"
                weight="semibold"
                className={styles.activateCardTitle}
              >
                {t("adventure_list.activate_heading")}
              </H3>
              <Button small to={"/activate"}>
                {t("adventure_list.activate_button")}
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

function EmptyState() {
  const { t } = useTranslation();

  return (
    <CenteredContent
      icon={IconMap}
      title={t("adventure_list.no_adventures_title")}
      text={
        <>
          <Trans i18nKey="adventure_list.no_adventures_info">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <Text element="span" weight="semibold" />
          </Trans>
          <div className="margin-8 margin-top">
            <Trans i18nKey="adventure_list.no_adventures_info_2">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <TextLink
                href={t("common.site_url ")}
                target="_blank"
                rel="noopener"
                className={styles.link}
              />
            </Trans>
          </div>
        </>
      }
    >
      <Button large to={"/activate"}>
        {t("adventure_list.activate_button")}
      </Button>
    </CenteredContent>
  );
}
